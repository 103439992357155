var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [!_vm.loaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  })]) : _vm._e(), _vm.loaded ? _c('BaseDetailviewContainer', {
    key: _vm.userUpdated,
    attrs: {
      "width": '720px',
      "padding": '32px'
    }
  }, [_c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('MyDetails'),
      "separator-line": true,
      "padding-top": '0',
      "button-name": _vm.$t('Edit'),
      "button-icon": 'edit_16_16.svg',
      "show-bot-divider": true,
      "identifier": 'myDetails',
      "parent-name": 'brokerSettings',
      "button-opens-accordion": true
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openBrokerPanelEdit(_vm.broker);
      }
    }
  }, [_c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_vm.broker.name ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("FullName")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.broker.name))])]) : _vm._e(), _vm.broker.email ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("e-mail")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.broker.email))])]) : _vm._e(), _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Password")))]), _c('p', {
    staticClass: "field-value underlined",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openChangePasswordModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangePassword")) + " ")])]), _vm.broker.phoneNumber ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("PhoneNum")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.broker.phoneNumber))])]) : _vm._e(), _vm.broker.personalCode ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("PersonalCode")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.broker.personalCode))])]) : _vm._e(), _vm.broker.types && !Object.values(_vm.broker.types).every(function (x) {
    return x.value === false;
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Role")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatUserObject(_vm.broker.types)))])]) : _vm._e(), _vm.broker.languages ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Languages")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatUserObject(_vm.broker.languages)) + " ")])]) : _vm._e(), _vm.broker.groups ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Groups")))]), _c('div', {
    staticClass: "field-value"
  }, [_vm.broker.groups.length ? _c('p', [_vm._v(" " + _vm._s(_vm.formatGroupField(_vm.broker)) + " ")]) : _vm._e(), !_vm.broker.groups.length ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("UnAllocated")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.broker.groups.length && _vm.broker.groups.filter(function (x) {
    return x.userDefault;
  }).length ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("DefaultGroup")))]), _c('div', {
    staticClass: "field-value"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formatDefaultGroup(_vm.broker.groups)) + " ")])])]) : _vm._e(), _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AccessLevel")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatUserRole(_vm.broker)))])]), _vm.broker.groups.length && _vm.broker.isGroupAdmin ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AdminInGroups")))]), _c('div', {
    staticClass: "field-value"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formatAdminInGroups(_vm.broker.groups)) + " ")])])]) : _vm._e(), _vm.broker.personalWWWPage ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("PersonalWWWPage")))]), _c('p', {
    staticClass: "field-value hoverable-link",
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.broker.personalWWWPage);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.broker.personalWWWPage) + " ")])]) : _vm._e(), _vm.broker.externalLinks && !_vm.broker.externalLinks.every(function (x) {
    return x.url === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("SocialMedia")))]), _c('div', {
    staticClass: "social",
    staticStyle: {
      "display": "flex",
      "gap": "12px"
    }
  }, _vm._l(_vm.broker.externalLinks.filter(function (link) {
    return link.url && link.type !== 'website';
  }), function (link) {
    return _c('div', {
      key: link.name,
      staticClass: "social-images",
      staticStyle: {
        "display": "flex",
        "gap": "12px"
      }
    }, [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "alt": '',
        "src": require(`../../assets/images/${link.type}.svg`)
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(link.url);
        }
      }
    })]);
  }), 0)]) : _vm._e(), _vm.broker.occupation && !Object.values(_vm.broker.occupation).every(function (x) {
    return x === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Occupation")))]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.broker.occupation).filter(function (x) {
    return x !== '';
  }), function (occupation) {
    return _c('div', {
      key: occupation,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "flex-wrap": "nowrap"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "464px"
      }
    }, [_vm._v(_vm._s(occupation))])]);
  }), 0)]) : _vm._e(), _vm.broker.profilePicture && _vm.broker.profilePicture.length && _vm.broker.profilePicture[0].name ? _c('v-row', {
    staticClass: "user-field-row",
    attrs: {
      "id": "lightgallery-user"
    }
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Image")))]), _c('img', {
    staticClass: "profile-picture",
    attrs: {
      "src": _vm.broker.profilePicture[0].name,
      "alt": ""
    },
    on: {
      "click": _vm.openImageGallery
    }
  })]) : _vm._e(), _vm.broker.publications && _vm.broker.publications.length ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Publications")))]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "24px"
    }
  }, _vm._l(_vm.broker.publications, function (publication) {
    return _c('div', {
      key: publication,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "4px",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "flex-start"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "464px"
      }
    }, [_vm._v(_vm._s(publication.title))]), _c('p', {
      staticClass: "hoverable-link",
      staticStyle: {
        "max-width": "464px"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(publication.url);
        }
      }
    }, [_vm._v(_vm._s(publication.url))])]);
  }), 0)]) : _vm._e(), _vm.broker.about && !Object.values(_vm.broker.about).every(function (x) {
    return x === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap"
    }
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("Description")))]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.broker.about).filter(function (x) {
    return x !== '';
  }), function (desc) {
    return _c('div', {
      key: desc,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "flex-wrap": "nowrap"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "464px"
      }
    }, [_vm._v(_vm._s(desc))])]);
  }), 0)]) : _vm._e()], 1)])]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('MyFeedback'),
      "separator-line": true,
      "identifier": 'feedback',
      "show-bot-divider": true,
      "parent-name": 'brokerSettings',
      "buttons-open-accordion": true,
      "padding-top": '32px',
      "button-name": _vm.$t('Edit'),
      "button-icon": 'edit_16_16.svg'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openBrokerPanelFeedback(_vm.broker);
      }
    }
  }, [!_vm.broker.clients ? _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(this.$t("NoFeedback")) + " ")]) : _vm._e(), _vm.broker.clients && _vm.feedback.length ? _c('v-row', [_c('div', {
    staticClass: "description content",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticClass: "description-item",
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    }
  }, [!_vm.showFullText ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.feedback,
      expression: "feedback"
    }],
    staticClass: "description-item1 content",
    staticStyle: {},
    attrs: {
      "outlined": "",
      "rows": "3",
      "id": "text-area",
      "readonly": ""
    },
    domProps: {
      "value": _vm.feedback
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.feedback = $event.target.value;
      }
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "show-more-button",
    class: {
      hidden: _vm.showFullText
    }
  }, [_c('button', {
    staticStyle: {
      "text-decoration-line": "underline"
    },
    attrs: {
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showText();
      }
    }
  }, [_vm.broker.clients && (_vm.feedback.length > 456 || this.rowCount > 5) ? _c('p', {
    staticClass: "link"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowMore")) + " ")]) : _vm._e()])]), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "width": "656px"
    }
  }, [_vm.showFullText ? _c('v-textarea', {
    staticClass: "description-textarea content",
    attrs: {
      "hide-details": "",
      "name": "input-7-1",
      "auto-grow": "",
      "readonly": "",
      "color": "black"
    },
    model: {
      value: _vm.feedback,
      callback: function ($$v) {
        _vm.feedback = $$v;
      },
      expression: "feedback"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "show-less-button",
    class: {
      hidden: !_vm.showFullText
    }
  }, [_c('button', {
    staticStyle: {
      "padding-left": "0px",
      "text-decoration-line": "underline"
    },
    attrs: {
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showTextLess();
      }
    }
  }, [_c('p', {
    staticClass: "link"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowLess")) + " ")])])])], 1)]) : _vm._e()], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Achievements'),
      "separator-line": true,
      "identifier": 'achievements',
      "parent-name": 'brokerSettings',
      "buttons-open-accordion": true,
      "show-bot-divider": true,
      "padding-top": '32px',
      "button-name": _vm.$t('Edit'),
      "button-icon": 'edit_16_16.svg'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openBrokerPanelAchievements(_vm.broker);
      }
    }
  }, [!_vm.broker.achievements ? _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(this.$t("NoAchievements")) + " ")]) : _vm._e(), _vm.broker.achievements ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.broker.achievements).filter(function (x) {
    return x !== '';
  }), function (achievement) {
    return _c('div', {
      key: achievement,
      staticClass: "content-normal",
      staticStyle: {
        "flex-wrap": "nowrap",
        "word-break": "break-word"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "656px"
      }
    }, [_vm._v(_vm._s(achievement))])]);
  }), 0)]) : _vm._e()]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('ListingLastImage'),
      "separator-line": true,
      "identifier": 'listingLastImage',
      "parent-name": 'brokerSettings',
      "button-opens-accordion": true,
      "padding-bottom": '0'
    }
  }, [_c('div', {
    staticStyle: {
      "max-width": "251px"
    }
  }, [_c('ImageUpload', {
    attrs: {
      "maxAmountOfImages": 1,
      "can-delete": true,
      "images": _vm.brokerLastImage,
      "label": _vm.$t('UploadImage'),
      "compress": true,
      "drag-and-drop": true
    },
    on: {
      "change": _vm.putImages
    }
  })], 1)])], 1) : _vm._e(), _vm.showChangePasswordModal ? _c('ChangePasswordModal', {
    attrs: {
      "broker": _vm.broker
    },
    on: {
      "close-modal": function ($event) {
        _vm.showChangePasswordModal = false;
      }
    }
  }) : _vm._e(), _vm.userSidepanelOpen ? _c('AddUserSidepanel', {
    attrs: {
      "edit": true,
      "editable-user": _vm.broker,
      "user-settings": true,
      "feedback-only": _vm.showFeedbackField,
      "achievements-only": _vm.showAchievementsField
    },
    on: {
      "closed": _vm.closeEditUserPanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }